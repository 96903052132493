import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO004_Banner.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO004Overview.js"
import SolarInfoOverview from "../../Figures/EnphaseInformation.jpg"


export default function PRO004_IntermediateSteps() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
      <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO004 - Home Battery DYI</h1>
      <br />
      <h2 style={{color: "white"}}>Step for step - Step 1</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>What steps?</h2>
        <p>Before buying battery, charger and other components, better to check a few things. There are two thing I have in mind:</p>
        <ol>
            <li>Sending and receiving power consumption inforation.</li>
            <ul>
                <li>The home battery needs to know when it is OK to charge or to discharge. So I need information about the net consumption in my house.</li>
            </ul>
            <li>Switching between net and battery power.</li>
            <ul>
                <li>Can I switch without the consumers knowing that their powersource has changed?</li>
            </ul>
        </ol>
        <h2>1. Consumption information</h2>
        <p>The solar panels on the roof are connected to Envoy inverters of Enphase. I think there are at least three ways to get the solar productionor total consumption data of my house to an ESP. Ordered by preference: <br />
        </p>
        <p>
          <ol>
            <li>Through the <a href="https://www.home-assistant.io/installation/raspberrypi/"> HomeAssistant </a>(HA) application on my RasPi.</li>
            <li>The <a href="https://developer.enphase.com/"> API of Enphase</a>.</li>
            <li>Through one of the <a href="https://www.fluvius.be/nl/thema/meters-en-meterstanden/digitale-meter/maak-je-meter-slim"> physical ports</a> of the smart meter. </li>
          </ol>
        </p>
        <p>the HomeAssistant app is capable of automatically detecting the <a href="https://www.home-assistant.io/integrations/enphase_envoy"> Enphase Envoy </a> and integrate it. The figure below shows all the information that is readily available.</p>
        <figure>
        <img width="50%"  src={SolarInfoOverview} style={{borderRadius: 20, marginLeft: "25%" , marginRight: "25%"}} alt="Available Solar panel information onverview." />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Available Solar panel information through HomeAssistant.</figcaption>
        </figure>
        <p>Which is all the info I need! I am planning to send the data to my eSP using the <a href="https://mqtt.org/">MQTT protocol</a>. For this to work I made the Pi running my HomeAssistant the mqtt broker, which I did following this <a href="https://randomnerdtutorials.com/how-to-install-mosquitto-broker-on-raspberry-pi/">RandomNerd tutorial</a>. 
        If you add the following lines to the <i>configuration.yaml</i> file of your HA:</p>
        <pre style={{maxHeight:"750px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
            mqtt:
              broker: *IP_of_mqtt_server*
              username: *UserName*
              password: *Password*
         `}</code>
        </pre>
        <p>Rebooting the HomeAssistant server should make MQTT available in the integrations.</p>
        <p>Getting the information of the solar panels in HA is one thing, now we need to get this information on the EPS8266. Action is needed on the HA side and on the ESP side:</p>
        <h3>HomeAssistant side</h3>
        <p>You can configure the HA to send mqtt messages by going to <i>Settings &gt; Automations</i> and adding a new automation. Most information you need is available though links on the settings page. Give t a name, a description. I Used a que of 10 and for a <b>Trigger</b> I chose a <i>Time pattern</i> 
        to automatically send the message each time the, the hour, minutes or secconds of the actual time match the value you place. I send it once a minute, which you can do by specifying for example "30" in the secconds field, or "/1" in the minute field.</p>
        <p>Then the <b>Action</b> to be taken at each trigger point we want to call a <i>Service</i>, more specifically <i>mqtt:publish</i>. Then you can choose the name of the topic and define the payload. The latter is what I struggles with the most, but what you can see below is what worked for me:</p>
        <pre style={{maxHeight:"750px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
            service: mqtt.publish
            data:
              topic: ConsumptionData
              retain: false
              payload: '{{(states("sensor.envoy_xxxxxxxxxxxx_current_energy_consumption")|int)}}'
         `}</code>
        </pre>
        <p>saving and possible restarting your HA server should result in a <i>automations.yaml</i> looking like the file below:</p>
        <pre style={{maxHeight:"750px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
        <code>{` 
          - id: 'xxxxxxxxxxxxx'
            alias: SendUpdatedConsumption
            description: Send a message over mqtt if the consumption value changes
            trigger:
            - platform: time_pattern
              seconds: '30'
            condition: []
            action:
            - service: mqtt.publish
              data:
                topic: ConsumptionData
                retain: false
                payload: '{{(states("sensor.envoy_xxxxxxxxxxxx_current_energy_consumption")|int)}}'
            mode: queued
            max: 10
         `}</code>
         </pre>
         <h3>eSP8266 side</h3>
         <p>Now that the HA is configured to send the message via mqtt, let's set up the ESP to receive it. Everything on the ESP side I was able to easily reproduce from this <a href="https://www.instructables.com/How-to-Use-MQTT-With-the-Raspberry-Pi-and-ESP8266/">Instructables </a> by &#91; Tomas Varnish &#93;, and the files available there.</p>
         <p>Well, this was a very short section... If it isn't as straight forward on the ESP side as I made it look, feel free to shout and let me know and I'll see where I can help.</p>
         <p>Next time, I'll quickly look if it is possible to switch between net- and battery power without confusing the loads attached to the output, before starting on the full software framework.</p>
        </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}